// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "./src/styles/global.css"
// Fonts
import "./src/styles/fonts.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// https://tailwindcss.com/docs/dark-mode
export const onInitialClientRender = () => {
  //   document.documentElement.classList.add("dark")
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    document.documentElement.classList.add("dark")
  }
}
